/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Modal, Spin } from 'antd'
import { type IUser } from '../../../../types/user'
import { useEffect, useMemo, useState, type FC } from 'react'
import { useGetContent } from '../../../../services/content'

import './ManageTemary.scss'
import { useGetUserAlloweance, useModifyAlloweance } from '../../../../services/userAlloweance'
import { type ISubject } from '../../../../types/subject'
import { type ITopic } from '../../../../types/topic'
import { useBranch } from '../../../../hooks/useBranch'
import { AlloweanceSubjects } from './components/AlloweanceSubjects'

interface Props {
  visible: boolean
  toggleModal: () => void
  user?: IUser
}

const ManageTemary: FC<Props> = ({ user, visible, toggleModal }) => {
  const { branch } = useBranch()
  const { contents, loading } = useGetContent()
  const { alloweance, loading: loadingAlloweance, refetch } = useGetUserAlloweance(user?._id)

  const { modifyAlloweance, loading: saving } = useModifyAlloweance()

  const [editedAlloweance, setEditedAlloweance] = useState<string[]>()
  const [editedAllowedTopics, setEditedAllowedTopics] = useState<string[]>()

  useEffect(() => {
    if (alloweance && !editedAlloweance && visible && !loading) {
      setEditedAlloweance((alloweance.content ?? []).map((t: any) => t._id))
    }

    if (alloweance && !editedAllowedTopics && visible && !loadingAlloweance) {
      setEditedAllowedTopics((alloweance.contentTopics ?? []).map((t: any) => t._id))
    }
  }, [alloweance, user, visible, loading])

  const handleClose = async () => {
    toggleModal()
    setEditedAlloweance(undefined)
    setEditedAllowedTopics(undefined)
    await refetch()
  }

  const handleSave = async () => {
    await modifyAlloweance({
      _id: alloweance._id!,
      tests: alloweance.tests?.map(({ _id }) => _id)!,
      subjects: alloweance.subjects?.map(({ _id }) => _id)! as string[],
      topics: editedAllowedTopics ?? [],
      content: editedAlloweance ?? [],
      contentTopics: editedAllowedTopics ?? [],
      type: 'temary'
    })
    handleClose()
    await refetch()
  }

  const handleCheckContent = (id: string) => {
    if (editedAlloweance?.includes(id)) {
      setEditedAlloweance((prev) => (prev?.filter((sb) => sb !== id)))
    } else {
      setEditedAlloweance((prev) => ([
        ...(prev ?? []),
        id]))
    }
  }

  const handleToggleTopic = (id: string) => {
    if (editedAllowedTopics?.includes(id)) {
      setEditedAllowedTopics((prev) => (prev?.filter((sb) => sb !== id)))
    } else {
      setEditedAllowedTopics((prev) => ([
        ...(prev ?? []),
        id]))
    }
  }

  const groupedContent = useMemo(() => contents?.reduce((acc: any, item) => {
    const subject = item.subject as ISubject
    const topic = item.topic as ITopic

    if (!acc[subject.name!]) {
      acc[subject.name] = {}
    }

    if (!acc[subject.name][`${topic.name}***${topic._id}`]) {
      acc[subject.name][`${topic.name}***${topic._id}`] = []
    }

    acc[subject.name][`${topic.name}***${topic._id}`].push(item)

    return acc
  }, {}), [contents])

  const formatted = useMemo(() => Object.entries(groupedContent ?? {}), [groupedContent])

  return (
        <Modal
            className='alloweance-modal'
            open={visible}
            onOk={handleSave}
            okText="Guardar"
            okButtonProps={{ loading: saving || loadingAlloweance, disabled: !alloweance?._id }}
            onCancel={handleClose}
        >
            <p className='user-title'>Temario / <span>{user?.name}</span></p>
            <h2 className='branch-title'>{branch?.name}</h2>
            <div className='content-part'>
              {(loading || loadingAlloweance) && <Spin /> }
              {(!loading && !loadingAlloweance) && (
                <>
                  {formatted.map((subject) => {
                    return (
                      <AlloweanceSubjects
                        editedAlloweance={editedAlloweance}
                        editedAllowedTopics={editedAllowedTopics}
                        key={subject[0]}
                        name={subject[0]}
                        toggleContent={handleCheckContent}
                        toggleTopic={handleToggleTopic}
                        topics={Object.entries(subject[1] as any)}
                        setEditedAlloweance={setEditedAlloweance}
                      />
                    )
                  }
                  )}
                </>
              )}
            </div>
        </Modal>
  )
}

export { ManageTemary }
