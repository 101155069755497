import { useState, type FC, type PropsWithChildren, useCallback } from 'react'

import './Box.scss'
import { Button, type ButtonProps } from 'antd'
import { Link } from 'react-router-dom'
import { IconChevronRight } from '@tabler/icons-react'
import clsx from 'clsx'

interface Action {
  label: string
  onClick: () => void
  type: ButtonProps['type']
  link?: string
  danger?: boolean
  loading?: boolean
  disabled?: boolean
}

type Props = PropsWithChildren<{
  defaultCollpsed?: boolean
  actions?: Action[]
  description?: string
  flex?: number
  title?: string
}>

const Box: FC<Props> = ({ defaultCollpsed, actions, children, description, flex = 1, title }) => {
  const [collapsed, setCollapsed] = useState(defaultCollpsed || false)

  const toggleCollapsed = useCallback(() => setCollapsed((prev) => !prev), [])

  return (
    <div className='box' style={{ flex }}>
      {title && (
        <div className='box-title'>
          <button className='collapser' onClick={toggleCollapsed}>
            <IconChevronRight size={13} color="white" className={!collapsed ? 'collapsed' : ''} />
          </button>
          <h3>{title}</h3>
        </div>
      )}
      <div className="scroller">
        {description && (
          <div className={clsx('box-description', collapsed && 'content-collapsed')}>
            <p>{description}</p>
          </div>
        )}
        <div className={clsx('box-actions', collapsed && 'content-collapsed')}>
          <div className='box-content'>
            {children}
          </div>
          {actions && actions.length > 0
            ? (
            <div className="box-actions">
              {actions?.map(({ label, link, type, onClick, danger, loading, disabled }, index) => {
                if (link) {
                  return (
                    <Link to={link} key={label} className={index !== 0 ? 'margined' : '' }>
                      <Button key={label} type={type}>
                        {label}
                      </Button>
                    </Link>
                  )
                }
                return (
                  <Button key={label} type={type} onClick={onClick} danger={danger} loading={loading} disabled={disabled}>
                    {label}
                  </Button>
                )
              })}
            </div>
              )
            : null}
        </div>
      </div>
    </div>
  )
}

export { Box }
