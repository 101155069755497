import { gql } from '@apollo/client'

export const GET_ALLCONTENT = gql`
query getAllContent {
  getAllContent{
    _id
    name
    type
    url
    branch { name }
    subject { name }
    topic {
      _id
      name
    }
  }
}
`
export const GET_SUBJECT_CONTENT = gql`
query getSubjectContent($input:String!) {
  getSubjectContent(input:$input){
    _id
    name
    type
    url
    branch {
      _id 
      name 
    }
    subject { 
      _id 
      name 
    }
    topic { 
      _id 
      name 
    }
  }
}
`

export const GET_BRANCH_CONTENT = gql`
  query getBranchContent($input: ContentInputByBranchAndUser!) {
    getBranchContent(input: $input) {
    _id
    name
    type
    url
    downloadable
    branch {
      _id 
      name 
    }
    subject { 
      _id 
      name 
    }
    topic { 
      _id 
      name 
    }
    }
  }
`
