import { Collapse, Modal, Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { Screen } from '../../components'
// import { Link } from 'react-router-dom'

import './SubjectsPage.scss'
import { useUser } from '../../hooks/useUser'
import { useState, type SyntheticEvent } from 'react'
import { useGetBranchContent, useRemoveContent } from '../../services/content'
import { Link } from 'react-router-dom'
import { useBranch } from '../../hooks/useBranch'
import { type IContent } from '../../types/content'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ISubject } from '../../types/subject'
import { type ITopic } from '../../types/topic'
import { useGetUserAlloweance } from '../../services/userAlloweance'
import ContentModal from './components/ContentModal/ContentModal'

const Panel = Collapse.Panel
const regex = /\.(docx|rtf|txt|pptx|pps|xlsx|xls|pdf)$/i

const SubjectsPage = () => {
  const { branch } = useBranch()
  const { refetch, grouped } = useGetBranchContent(branch?._id)
  const { isAdmin, isSuperAdmin, user } = useUser()
  const [isOpen, setIsOpen] = useState(false)
  const { removeContent, loading } = useRemoveContent()

  const [removeModal, setRemoveModal] = useState(false)

  const { alloweance, loading: loadingAlloweance } = useGetUserAlloweance(user?._id)

  const [selectedContent, setSelectedContent] = useState<IContent>()

  const renderHeader = (name: string) => {
    return (
      <div className='subject-header'>
        <p>{name}</p>
      </div>
    )
  }

  const onCancel = () => {
    setSelectedContent(undefined)
    setIsOpen(false)
  }

  const onClickAddContent = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOpen(true)
  }

  const handleFinish = async () => {
    setSelectedContent(undefined)
    await refetch()
  }

  const handleRemove = async () => {
    await removeContent(selectedContent?._id as string)
    await refetch()
    setRemoveModal(false)
  }

  const allowedTopics = (alloweance?.contentTopics || []).map((c) => c._id)

  return (
    <Screen id="subjects" title="Temario">
      {
        (isAdmin || isSuperAdmin) && (
          <div className='add-content-container'>
            <button className='add-content' onClick={onClickAddContent}>Añadir contenido</button>
          </div>
        )
      }
      {loadingAlloweance
        ? (
          <Spin />
        )
        : (
          <div className='subjects-content'>
            <Collapse>
              {Object.entries(grouped || {}).map(([subject, subjectContent]) => (
                <Panel header={renderHeader(subject)} key={subject}>
                  <Collapse>
                    {Object.entries(subjectContent as any).map(([topic, topicContent]) => {
                      const [topicName, topicId] = topic.split('***')

                      const shouldShowTopic = allowedTopics?.includes(topicId)

                      const content = (topicContent as IContent[])?.filter((c) => !allowedTopics.includes(c._id!))
                      if (!content || content.length === 0 || !shouldShowTopic) return null
                      return (
                        <Panel header={topicName} key={topic}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {content.map((it) => (
                              <div key={it.name} className='content-line'>
                                {!regex.test(it.url) && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                                {regex.test(it.url) && <Link to={`/doc/${it.url.split('/')[3]}/${encodeURIComponent(it.type)}/${it.name}?isEnabled=${it.downloadable}`} >{it.name}</Link>}
                                {it.type === 'image' && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                                {it.downloadable && <a className='download-content' href={it.url}><DownloadOutlined /></a>}

                                {isAdmin && (
                                  <div className='line-actions'>
                                    <button>
                                      <IconEdit color="#4cae4c" size={20} onClick={() => {
                                        setSelectedContent({
                                          ...it,
                                          subject: (it.subject as ISubject)._id!,
                                          topic: (it.topic as ITopic)._id!
                                        })
                                        setTimeout(() => {
                                          setIsOpen(true)
                                        }, 500)
                                      }} />
                                    </button>
                                    <button onClick={() => {
                                      setRemoveModal(true)
                                      setSelectedContent(it)
                                    }}>
                                      <IconTrash color="#F47174" size={20} />
                                    </button>
                                    {loading && (
                                      <Spin />
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </Panel>
                      )
                    })}
                  </Collapse>
                </Panel>
              ))}
            </Collapse>
          </div>
        )}
      <Modal
        open={removeModal}
        onCancel={() => setRemoveModal(false)}
        okButtonProps={{
          danger: true,
          loading
        }}
        onOk={handleRemove}
        okText="Eliminar"
        title="Eliminar temario"
      >
        {'Esto eliminará este contenido y no podrás recuperarlo". ¿Estas seguro de que quieres continuar?'}
      </Modal>
      <ContentModal selectedSubject={selectedContent} onFinish={handleFinish} isOpen={isOpen} title='Contenido' onCancel={onCancel} />
    </Screen>
  )
}

export { SubjectsPage }
