import { gql } from '@apollo/client'

export const CREATE_CONTENT = gql`
mutation createContent($input:ContentInput!){
  createContent(input:$input){
    name
    type
    url
    branch{name}
    subject{name}
    topic{name}
  } 
}
`

export const UPDATE_CONTENT = gql`
mutation updateContent($input:ContentInput!){
  updateContent(input: $input){
    name
    type
    url
    branch{name}
    subject{name}
    topic{name}
  } 
}
`

export const REMOVE_CONTENT = gql`
mutation removeContent($input: String!) {
  removeContent(input: $input)
}
`
