import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_ALLCONTENT, GET_BRANCH_CONTENT, GET_SUBJECT_CONTENT } from './queries'
import { CREATE_CONTENT, REMOVE_CONTENT, UPDATE_CONTENT } from './mutations'
import { type IContent } from '../../types/content'
import { useBranch } from '../../hooks/useBranch'
import { useUser } from '../../hooks/useUser'
import { type ISubject } from '../../types/subject'
import { type ITopic } from '../../types/topic'

interface GetContentResponse {
  getAllContent: IContent[]
  getSubjectContent: IContent[]
}
export const useGetContent = () => {
  const { data, loading, refetch } = useQuery<GetContentResponse>(GET_ALLCONTENT, { fetchPolicy: 'network-only' })

  return {
    contents: data?.getAllContent,
    loading,
    refetch
  }
}

export const useGetSubjectContent = () => {
  const [getContent, { loading, data }] = useLazyQuery<GetContentResponse>(GET_SUBJECT_CONTENT, { fetchPolicy: 'network-only' })

  return {
    getContent,
    contents: data?.getSubjectContent,
    loading
  }
}

export const useGetBranchContent = (selectedBranch?: string) => {
  const { branch } = useBranch()
  const { user } = useUser()
  const { data, loading, refetch } = useQuery(GET_BRANCH_CONTENT, {
    variables: {
      input: { branch: branch?._id || selectedBranch, user: user?._id }
    },
    skip: !(selectedBranch || branch),
    fetchPolicy: 'network-only'
  })

  const grouped = (data?.getBranchContent as IContent[] || []).reduce((acc: any, item) => {
    const subject = item.subject as ISubject
    const topic = item.topic as ITopic

    if (!acc[subject.name]) {
      acc[subject.name] = {}
    }

    if (!acc[subject.name][`${topic.name}***${topic._id}`]) {
      acc[subject.name][`${topic.name}***${topic._id}`] = []
    }

    acc[subject.name][`${topic.name}***${topic._id}`].push(item)

    return acc
  }, {})

  return {
    grouped,
    contents: data?.getBranchContent as IContent[],
    loading,
    refetch
  }
}

export const useCreateContent = () => {
  const [action, { loading }] = useMutation(CREATE_CONTENT)

  const createContent = async (input: IContent) => {
    await action({ variables: { input: { ...input } } })
  }

  return { createContent, loading }
}

export const useUpdateContent = () => {
  const [action, { loading }] = useMutation(UPDATE_CONTENT)

  const updateContent = async (input: IContent) => {
    await action({ variables: { input: { ...input } } })
  }

  return { updateContent, loading }
}

export const useRemoveContent = () => {
  const [action, { loading }] = useMutation(REMOVE_CONTENT)

  const removeContent = async (input: string) => {
    await action({ variables: { input } })
  }

  return { removeContent, loading }
}
